import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js';
import { parseCountryCode } from '@pmi.web/countries';
import { PrimaryButton } from '@pmi.web/react-common';
import {
  CustomerRegistrationForm,
  type ICustomerRegistrationFormData
} from '@pmi.web/react-user-forms';
import { Disclaimer } from 'components/common/Disclaimer/Disclaimer';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { useRegistrationContext } from 'contexts/registration-context/useRegistrationContext';
import { useCustomerRegistration } from 'hooks/useCustomerRegistration';
import { useProspectOnboard } from 'hooks/useProspectOnboard';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { startSignIn } from 'services/AuthService';

export function CustomerRegistrationV2() {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const appInsights = useAppInsightsContext();
  const { redirectUrl, prospectData, resumeToken } = useRegistrationContext();

  const [formData, setFormData] = useState({
    ...prospectData,
    locale: language,
    sponsor: prospectData?.sponsor ?? '',
    sponsorId: prospectData?.sponsor ?? '',
    address: {
      ...prospectData?.address,
      country: prospectData?.address?.country ?? ''
    }
  });

  const {
    data: registrationResult,
    error: registrationError,
    isLoading: isLoadingCustomerRegistration,
    register
  } = useCustomerRegistration();

  const {
    data: onboardingResult,
    error: prospectCreationError,
    isLoading: isLoadingProspectOnboarding,
    onboard
  } = useProspectOnboard();

  /**
   * Business errors reported by the API.
   * Proceed with caution. You will only find hacks inside until we can
   * know for sure what the UM service is returning in case of errors.
   * (╯°□°）╯︵ ┻━┻
   *
   * For now, we can only brace ourselves and hope for the best!
   */
  const businessLogicErrors = useMemo(() => {
    const errors: Partial<
      Record<
        keyof ICustomerRegistrationFormData | 'address.addressLine1',
        { readonly message: string; readonly type: string }
      >
    > = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const backendError: any = registrationError ?? prospectCreationError;

    if (backendError && typeof backendError === 'object') {
      const knownBackendError: {
        readonly detail: string;
        readonly errors: Record<string, string>;
      } = {
        detail: 'detail' in backendError ? (backendError.detail as string) : '',
        errors:
          'Errors' in backendError
            ? backendError.Errors
            : 'errors' in backendError
            ? backendError.errors
            : {}
      };

      // Duplicate address error
      if (knownBackendError.detail === 'address is flagged as duplicate') {
        errors['address.addressLine1'] = {
          type: 'duplicate_address',
          message: t('Address is already in use')
        };
      } // Bad address error
      else if (
        typeof knownBackendError.errors === 'object' &&
        Object.keys(knownBackendError.errors).find(k => k.startsWith('address'))
      ) {
        errors['address.addressLine1'] = {
          type: 'duplicate_address',
          message: t('Address is invalid')
        };
      }

      // Duplicate email error
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['email'] &&
        knownBackendError.errors['email'].includes('email is taken')
      ) {
        errors['email'] = {
          type: 'duplicate_email',
          message: t('Email is already in use')
        };
      }

      // Too young error
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors['dateOfBirth'] &&
        knownBackendError.errors['dateOfBirth'].includes('18')
      ) {
        errors['dateOfBirth'] = {
          type: 'too_young',
          message: t('You must be at least 18 years old')
        };
      }

      // Other errors
      if (
        typeof knownBackendError.errors === 'object' &&
        knownBackendError.errors
      ) {
        Object.keys(knownBackendError.errors)
          .filter(
            k =>
              !k.startsWith('address') && k !== 'email' && k !== 'dateOfBirth'
          )
          .forEach(k => {
            errors[k as keyof ICustomerRegistrationFormData] = {
              type: 'unknow_error',
              message: knownBackendError.errors[k] ?? t('Something went wrong')
            };
          });
      }
    }

    return errors;
  }, [registrationError, prospectCreationError, t]);

  const uniqueKey = useMemo(() => {
    // Currently, the form state is inside the component. If some validation
    // outside of it fails, it has no information about it. These failures
    // are "businessLogicErrors". To ensure the component gets rendered
    // correctly, we set a unique key and update it when the errors change.
    return (Math.floor(Math.random() * 99999999) + 1).toString();
  }, [businessLogicErrors]);

  const isLoading =
    isLoadingCustomerRegistration || isLoadingProspectOnboarding;
  const result = registrationResult || onboardingResult;

  const trackCustomerRegistrationDone = useTrackEvent(
    appInsights,
    'CustomerRegistrationDone',
    {}
  );

  useEffect(() => {
    if (result) {
      trackCustomerRegistrationDone({});
      appInsights.core.flush(false);

      startSignIn({
        nonceToken: result.otpNonce,
        prospectId: result.id,
        currentState: JSON.stringify({
          redirectUrl: redirectUrl,
          locale: language
        })
      });
    }
  }, [result]);

  useEffect(() => {
    setFormData(state => ({ ...state, locale: language }));
  }, [language]);

  return (
    <>
      {(registrationError || prospectCreationError) &&
        Object.keys(businessLogicErrors).length === 0 && (
          <div className="mb-md">
            <Disclaimer type="error">
              <h3>{t('Something went wrong')}</h3>
              <p>{t('Please contact our support team.')}</p>
            </Disclaimer>
          </div>
        )}

      {isLoading ||
        (result && (
          <div className="min-h-[150px] flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ))}

      {!isLoading && !result && (
        <>
          <CustomerRegistrationForm
            disableEmailChanges={!!resumeToken}
            key={uniqueKey}
            country={parseCountryCode(formData.address.country)}
            language={formData.locale}
            formId="customer-registration-form"
            onSubmit={data => {
              const prospect = {
                ...formData,
                ...data
              };

              setFormData(prospect as any);

              if (prospectData?.id) {
                onboard(prospect, resumeToken);
              } else {
                register(prospect);
              }
            }}
            defaultValues={formData}
            businessLogicErrors={businessLogicErrors}
          />

          <div className="w-full flex justify-end mt-md">
            <PrimaryButton
              disabled={isLoading}
              type="submit"
              form="customer-registration-form"
            >
              {t('Submit')}
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
}
